import splashImg from '../../assets/images/background/splashImg.webp';
import appLogo from '../../assets/images/nugen-asset/nugenLogoSmall.webp';
import orangeClip from '../../assets/images/nugen-asset/orange.webp';
import blueberryClip from '../../assets/images/nugen-asset/blueberry.webp';
import leafClip from '../../assets/images/nugen-asset/leaf.webp';
import nucleotideClip from '../../assets/images/nugen-asset/nucleoImg.webp';

import "./Products.less";

import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { EffectNumber, EffectString, Parallax, ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax';
interface Props {
    phoneView: any;
    windowDimension: any;
    parallaxView: any;
}

const textBoxAnimate: { size: any, x: EffectString, y: EffectString, scale?: EffectNumber }[] = [
    { size: 'large', x: ['0px', '0px'], y: ['40%', '0%'], scale: [0.13, 0.13] },
    { size: 'medium', x: ['0px', '0px'], y: ['55%', '0%'], scale: [0.13, 0.13] },
    { size: 'small', x: ['0px', '0px'], y: ['70%', '0%'], scale: [0.13, 0.13] },
    { size: 'xsmall', x: ['0px', '0px'], y: ['90%', '0%'], scale: [0.13, 0.13] },
    { size: 'xxsmall', x: ['0px', '0px'], y: ['90%', '0%'], scale: [0.13, 0.13] },
    { size: 'xxxsmall', x: ['0px', '0px'], y: ['90%', '0%'], scale: [0.13, 0.13] },
]

const leafAnimate: { size: any, x: EffectString, y: EffectString, scale?: EffectNumber }[] = [
    { size: 'large', x: ['-60%', '-15%'], y: ['-40%', '0%'], scale: [0.13, 0.13] },
    { size: 'medium', x: ['-60%', '-15%'], y: ['-40%', '0%'], scale: [0.15, 0.15] },
    { size: 'small', x: ['-60%', '-15%'], y: ['-40%', '0%'], scale: [0.17, 0.17] },
    { size: 'xsmall', x: ['-65%', '-15%'], y: ['-40%', '0%'], scale: [0.22, 0.22] },
    { size: 'xxsmall', x: ['-65%', '35%'], y: ['-25%', '0%'], scale: [0.22, 0.22] },
    { size: 'xxxsmall', x: ['-65%', '35%'], y: ['-25%', '0%'], scale: [0.22, 0.22] },
]

const nucleoAnimate: { size: any, x: EffectString, y: EffectString, scale?: EffectNumber }[] = [
    { size: 'large', x: ['60%', '15%'], y: ['-40%', '-5%'], scale: [0.23, 0.23] },
    { size: 'medium', x: ['60%', '15%'], y: ['-40%', '-5%'], scale: [0.25, 0.25] },
    { size: 'small', x: ['60%', '15%'], y: ['-40%', '-5%'], scale: [0.27, 0.27] },
    { size: 'xsmall', x: ['65%', '15%'], y: ['-40%', '-5%'], scale: [0.32, 0.32] },
    { size: 'xxsmall', x: ['65%', '-40%'], y: ['-25%', '0%'], scale: [0.32, 0.32] },
    { size: 'xxxsmall', x: ['65%', '-40%'], y: ['-25%', '0%'], scale: [0.32, 0.32] },
]

const blueberryAnimate: { size: any, x: EffectString, y: EffectString, scale?: EffectNumber }[] = [
    { size: 'large', x: ['-30%', '-20%'], y: ['30%', '0%'], scale: [0.1, 0.1] },
    { size: 'medium', x: ['-40%', '-25%'], y: ['30%', '5%'], scale: [0.13, 0.13] },
    { size: 'small', x: ['-40%', '-25%'], y: ['30%', '5%'], scale: [0.16, 0.16] },
    { size: 'xsmall', x: ['-40%', '-25%'], y: ['30%', '5%'], scale: [0.19, 0.19] },
    { size: 'xxsmall', x: ['-40%', '-25%'], y: ['20%', '5%'], scale: [0.19, 0.19] },
    { size: 'xxxsmall', x: ['-40%', '-25%'], y: ['20%', '5%'], scale: [0.19, 0.19] },
]

const orangeAnimate: { size: any, x: EffectString, y: EffectString, scale?: EffectNumber }[] = [
    { size: 'large', x: ['35%', '20%'], y: ['30%', '0%'], scale: [0.21, 0.21] },
    { size: 'medium', x: ['45%', '20%'], y: ['30%', '5%'], scale: [0.24, 0.24] },
    { size: 'small', x: ['45%', '20%'], y: ['30%', '5%'], scale: [0.26, 0.26] },
    { size: 'xsmall', x: ['45%', '20%'], y: ['30%', '5%'], scale: [0.33, 0.33] },
    { size: 'xxsmall', x: ['45%', '20%'], y: ['20%', '5%'], scale: [0.33, 0.33] },
    { size: 'xxxsmall', x: ['45%', '20%'], y: ['20%', '5%'], scale: [0.33, 0.33] },
]

const bgAnimate: { size: any, x: EffectString, y: EffectString, scale?: EffectNumber }[] = [
    { size: 'large', x: ['60%', '10%'], y: ['0px', '0px'], scale: [1, 1] },
    { size: 'medium', x: ['60%', '10%'], y: ['0px', '0px'], scale: [1, 1.5] },
    { size: 'small', x: ['60%', '10%'], y: ['0px', '0px'], scale: [1, 1.5] },
    { size: 'xsmall', x: ['60%', '10%'], y: ['0px', '0px'], scale: [1, 3] },
    { size: 'xxsmall', x: ['60%', '10%'], y: ['0px', '0px'], scale: [1, 3] },
    { size: 'xxxsmall', x: ['60%', '10%'], y: ['0px', '0px'], scale: [1, 3] },
]

const Products = forwardRef(({ phoneView, windowDimension, parallaxView }: Props, ref) => {
    const intl = useIntl();
    const [logoPosition, setLogoPosition] = useState<boolean>(false);
    const [leafSetting, setLeafSetting] = useState<any>({ size: 'large', x: ['0px', '0px'], y: ['0px', '0px'], scale: [0, 0] });
    const [nucleoSetting, setNucleoSetting] = useState<any>({ size: 'large', x: ['0px', '0px'], y: ['0px', '0px'], scale: [0, 0] });
    const [blueberrySetting, setBlueberrySetting] = useState<any>({ size: 'large', x: ['0px', '0px'], y: ['0px', '0px'], scale: [0, 0] });
    const [orangeSetting, setOrangeSetting] = useState<any>({ size: 'large', x: ['0px', '0px'], y: ['0px', '0px'], scale: [0, 0] });
    const [bgSetting, setBgSetting] = useState<any>({ size: 'large', x: ['0px', '0px'], y: ['0px', '0px'], scale: [0, 0] });
    const [textboxSetting, setTextBoxSetting] = useState<any>({ size: 'large', x: ['0px', '0px'], y: ['0px', '0px'], scale: [0, 0] });
    const [targetElement, setTarget] = useState<HTMLDivElement | null>(null);
    const targetRef = useRef<null | HTMLDivElement>(null);

    // useEffect(() => {
    //     console.log(targetRef!);
    //     // setTarget(targetRef!.current!);
    // }, [targetRef]);

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 650) {
                setLogoPosition(true)
            } else {
                setLogoPosition(false)
            }
        });

    }, []);

    useEffect(() => {
        const useSize = leafAnimate.filter(x => x.size === windowDimension);
        const useSize2 = nucleoAnimate.filter(x => x.size === windowDimension);
        const useSize3 = blueberryAnimate.filter(x => x.size === windowDimension);
        const useSize4 = orangeAnimate.filter(x => x.size === windowDimension);
        const useSize5 = bgAnimate.filter(x => x.size === windowDimension);
        const useSize6 = textBoxAnimate.filter(x => x.size === windowDimension);
        setLeafSetting(useSize[0]);
        setNucleoSetting(useSize2[0]);
        setBlueberrySetting(useSize3[0]);
        setOrangeSetting(useSize4[0]);
        setBgSetting(useSize5[0]);
        setTextBoxSetting(useSize6[0]);
    }, [windowDimension]);

    const productRef = useRef<null | HTMLDivElement>(null);
    useImperativeHandle(ref, () => ({
        scrollIntoView: () => {
            productRef!.current!.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    }));

    return (
        <>
            {/* Header Logo */}
            {
                logoPosition === true && phoneView === true ?
                    <div className="logoFixedContainer">
                        <img alt="logoShort" className="logoShort" src={appLogo} />
                    </div>
                    :
                    null
            }

            {/* Content Section */}
            {
                parallaxView === false ?
                    // No Parallax
                    <>
                        <div className="section2Wrapper">
                            <div className="productSection">
                                <div style={{ overflow: "hidden", width: "100%" }}>
                                    <img alt="splashImg" className="splashImgBg" src={splashImg} />
                                </div>

                                <div className="contentSection">
                                    <div className="contentBox">
                                        <div className="prdContent">
                                            <div className="prdLeafClipContainer" ref={productRef}>
                                                <img alt="leaf1" className="prdLeafClip" src={leafClip} />
                                            </div>
                                            <div className="prdBlueberryClipContainer">
                                                <img alt="blueberry" className="prdBlueberryClip" src={blueberryClip} />
                                            </div>
                                            <div className="prdNucleoClipContainer">
                                                <img alt="nucleo" className="prdNucleoClip" src={nucleotideClip} />
                                            </div>
                                            <div className="prdOrangeClipContainer">
                                                <img alt="orange" className="prdOrangeClip" src={orangeClip} />
                                            </div>
                                            <div className="prdTextBox">
                                                <h2 className="titleSizing">{intl.formatMessage({ id: "PRODUCT_NAME_2" })}</h2>
                                                <p className="fontSizing">{intl.formatMessage({ id: "PRODUCT_DESC_3" })}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    // Parallax
                    <>
                        {/* <div className="sticky-container">
                            {targetElement && (
                                <>
                                    <Parallax
                                        className="test"
                                        translateX={[0, 0]}
                                        translateY={[0, 50]}
                                        targetElement={targetElement}
                                    >
                                        Testimonial
                                    </Parallax>
                                    <Parallax
                                        className="test"
                                        translateX={[0, 100]}
                                        translateY={[0, 50]}
                                        targetElement={targetElement}
                                    >
                                        <img src={leafClip} alt="leaf" width={'10%'} />
                                    </Parallax>
                                </>
                            )}
                        </div> */}
                        <div className="parallaxContainerP2"  ref={productRef}>

                            <ParallaxBanner style={{ aspectRatio: '1/ 0.5', position: "unset" }}>
                                <Parallax style={{ width: '100%', height: '50%', position: 'sticky', marginTop: '50vh', zIndex: 0 }}>
                                    {/* control animation start end */}
                                    <div ref={el => { targetRef.current = el; setTarget(el); }}></div>
                                </Parallax>
                                <ParallaxBannerLayer className="splashImgDiv"
                                    image={splashImg}
                                    speed={-20}
                                    translateX={bgSetting.x}
                                    translateY={bgSetting.y}
                                    scale={bgSetting.scale}
                                    easing="easeOutSine"
                                />
                                <ParallaxBannerLayer className="prdContent1Wrapper"
                                    speed={-35}
                                    translateY={textboxSetting.y}
                                    easing="easeInQuad"
                                    children={
                                        (
                                            <div className="textBox1">
                                                <div className="prdContentBox1">
                                                    <div className="prdContentText1">
                                                        <h2 className="titleSizing">{intl.formatMessage({ id: "PRODUCT_NAME_2" })}</h2>
                                                        <p className="fontSizing">{intl.formatMessage({ id: "PRODUCT_DESC_3" })}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                ></ParallaxBannerLayer>
                                {
                                    targetElement !== null ?
                                        <>
                                            <ParallaxBannerLayer className="leafDiv"
                                                targetElement={targetElement}
                                                image={leafClip}
                                                speed={-20}
                                                scale={leafSetting.scale}
                                                opacity={[3, 0]}
                                                translateX={leafSetting.x}
                                                translateY={leafSetting.y}
                                                easing="easeInQuad"
                                            />
                                            <ParallaxBannerLayer className="nucleotideDiv"
                                                targetElement={targetElement}
                                                image={nucleotideClip}
                                                speed={-20}
                                                scale={nucleoSetting.scale}
                                                opacity={[3, 0]}
                                                translateX={nucleoSetting.x}
                                                translateY={nucleoSetting.y}
                                                easing="easeInQuad"
                                            />
                                            <ParallaxBannerLayer className="blueberryDiv"
                                                targetElement={targetElement}
                                                image={blueberryClip}
                                                speed={-20}
                                                scale={blueberrySetting.scale}
                                                opacity={[3, 0]}
                                                translateX={blueberrySetting.x}
                                                translateY={blueberrySetting.y}
                                                easing="easeInQuad"
                                            />
                                            <ParallaxBannerLayer className="orangeDiv"
                                                targetElement={targetElement}
                                                image={orangeClip}
                                                speed={-20}
                                                scale={orangeSetting.scale}
                                                opacity={[3, 0]}
                                                translateX={orangeSetting.x}
                                                translateY={orangeSetting.y}
                                                easing="easeInQuad"
                                            />
                                        </>
                                        :
                                        <></>
                                }


                            </ParallaxBanner>
                        </div>
                    </>
            }
        </>
    )
})

export default Products;