import React from "react";
import Home from "./pages/Home";

export interface RouteInfo {
    id: string,
    name: string,
    path: string,
    component?: any,
    children?: RouteInfo[]
}

const ReactLazyPreload = (importStatement: any) => {
    const Component: any = React.lazy(importStatement);
    Component.preload = importStatement;
    return Component;
};

//preload pages
const HomePage = ReactLazyPreload(() => import("./pages/Home"));
HomePage.preload();


export const protectedRoutes: RouteInfo[] = [
    {
        id: 'Home',
        name: 'Home',
        path: '/home',
        component: HomePage
    }
];