import { useEffect } from "react";
import { SET_LANGUAGE, useLayout } from "../hooks/LayoutContext";
import './LanguageSwitcher.less';

interface Props {
    refHead?: any;
}
const LanguageSwitcher = ({ refHead }: Props) => {
    const { layoutDispatch } = useLayout();

    const getLanguage = (loc: string) => {
        localStorage.setItem('pfxl', loc);
        layoutDispatch({ type: SET_LANGUAGE, payload: loc });
        if (refHead.current) {
            refHead.current.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    }

    return (
        <div className="language-switcher-container">
            <p>
                <span onClick={() => getLanguage('en')}>EN</span>
                <span onClick={() => getLanguage('zh-CN')}>中文</span>
                <span onClick={() => getLanguage('ms')}>BM</span>
            </p>
        </div>
    )
}

export default LanguageSwitcher;