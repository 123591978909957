import prdImg2 from '../../assets/images/nugen-asset/productImg2.webp';
import forestBg from '../../assets/images/background/forestBg.webp';
import "./Testimonial.less";
import "./Products.less";
import { useIntl } from "react-intl";
import { EffectNumber, EffectString, ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax';
import { useEffect, useState } from 'react';
// import TestimonialCarousel from "../Testimonial/TestimonialCarousel";
interface Props {
    phoneView: any;
    windowDimension: any;
    parallaxView: any;
}

const forestBgAnimate: { size: any, x: EffectString, y: EffectString, scale?: EffectNumber, opacity?: EffectNumber }[] = [
    { size: 'large', x: ['0%', '0%'], y: ['0%', '-45%'], scale: [1, 1], opacity: [0, 6] },
    { size: 'medium', x: ['0%', '0%'], y: ['0%', '-45%'], scale: [1, 1], opacity: [0, 6] },
    { size: 'small', x: ['0%', '0%'], y: ['0%', '-45%'], scale: [1, 1], opacity: [0, 6] },
    { size: 'xsmall', x: ['0%', '0%'], y: ['0%', '-45%'], scale: [1, 1], opacity: [0, 6] },
    { size: 'xxsmall', x: ['0%', '0%'], y: ['0%', '-45%'], scale: [1, 1], opacity: [0, 6] },
    { size: 'xxxsmall', x: ['0%', '0%'], y: ['0%', '-45%'], scale: [1, 1], opacity: [0, 6] },
]

const textBoxAnimate: { size: any, x: EffectString, y: EffectString, scale?: EffectNumber, opacity?: EffectNumber }[] = [
    { size: 'large', x: ['0%', '0%'], y: ['25%', '-10%'], scale: [1, 1], opacity: [1, 1] },
    { size: 'medium', x: ['0%', '0%'], y: ['25%', '-10%'], scale: [1, 1], opacity: [1, 1] },
    { size: 'small', x: ['0%', '0%'], y: ['25%', '-10%'], scale: [1, 1], opacity: [1, 1] },
    { size: 'xsmall', x: ['0%', '0%'], y: ['25%', '-10%'], scale: [1, 1], opacity: [1, 1] },
    { size: 'xxsmall', x: ['0%', '0%'], y: ['25%', '-10%'], scale: [1, 1], opacity: [1, 1] },
    { size: 'xxxsmall', x: ['0%', '0%'], y: ['25%', '-10%'], scale: [1, 1], opacity: [1, 1] },
]

const Products3 = ({ phoneView, windowDimension, parallaxView }: Props) => {
    const intl = useIntl();
    const [forestBgSetting, setForestBgSetting] = useState<any>({ size: 'large', x: ['0px', '0px'], y: ['0px', '0px'], scale: [0, 0], opacity: [0, 0], aspectRatio: '1/1' });
    const [textBoxSetting, setTextBoxSetting] = useState<any>({ size: 'large', x: ['0px', '0px'], y: ['0px', '0px'], scale: [0, 0], opacity: [0, 0], aspectRatio: '1/1' });

    useEffect(() => {
        const useSize = forestBgAnimate.filter(x => x.size === windowDimension);
        const useSize2 = textBoxAnimate.filter(x => x.size === windowDimension);
        setForestBgSetting(useSize[0]);
        setTextBoxSetting(useSize2[0]);
    }, [windowDimension]);

    return (
        <>
            {
                parallaxView === false ?
                    <div className="productSection3">
                        <div className="contentSection3">
                            <div className="contentBox3">
                                <div className="prdContent3">
                                    <div className="prdTextBox">
                                        <h2>{intl.formatMessage({ id: "PRODUCT_TITLE_2" })}</h2>
                                    </div>

                                    <div className="prdDisplayBox">
                                        <div style={{ width: "100%" }}>
                                            <div style={{ zIndex: 50, position: "relative", display: "flex", justifyContent: "center" }}>
                                                <img alt="productImg2" className="productImg2Clip" src={prdImg2} />
                                            </div>
                                        </div>
                                        <div style={{ width: "100%" }}>
                                            <p className="fontSizing">{intl.formatMessage({ id: "PRODUCT_DESC_5" })}</p>
                                            <p className="fontSizing">{intl.formatMessage({ id: "PRODUCT_DESC_6" })}</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div style={{ overflow: "hidden", width: "100%" }}>
                            <img style={{ aspectRatio: '1/1' }} alt="forestBg" className="forestImgBg" src={forestBg} />
                        </div>
                    </div>
                    :
                    <div className="parallaxContainerP4">
                        <ParallaxBanner style={{ aspectRatio: '1/0.5', position: "unset" }}>
                            <ParallaxBannerLayer className="forestBgDiv"
                                image={forestBg}
                                speed={-10}
                                translateY={forestBgSetting.y}
                                scale={forestBgSetting.scale}
                                opacity={forestBgSetting.opacity}
                                easing="easeOutQuad"
                            ></ParallaxBannerLayer>
                            <ParallaxBannerLayer className="textbox1Div"
                                speed={-10}
                                translateY={textBoxSetting.y}
                                easing="easeInQuad"
                                children={
                                    <>
                                        <div className="textBox3">
                                            <div className="prdContentBox3">
                                                <div className="prdContentText3">
                                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                        <h2>{intl.formatMessage({ id: "PRODUCT_TITLE_2" })}</h2>
                                                    </div>

                                                    <div className="prdContentText3Wrapper">
                                                        <div style={{ width: "100%" }}>
                                                            <div style={{ zIndex: 50, position: "relative", display: "flex", justifyContent: "center" }}>
                                                                <img alt="productImg2" className="prdImg2Div" src={prdImg2} />
                                                            </div>
                                                        </div>
                                                        <div style={{ width: "100%" }}>
                                                            <p className="fontSizing">{intl.formatMessage({ id: "PRODUCT_DESC_5" })}</p>
                                                            <p className="fontSizing">{intl.formatMessage({ id: "PRODUCT_DESC_6" })}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                            ></ParallaxBannerLayer>
                        </ParallaxBanner>
                    </div>}
        </>
    )
}

export default Products3;