import "./Footer.less";
import logosmall from '../../assets/images/nugen-asset/logosmall.webp';
import Product from '../../assets/images/nugen-asset/productImg5.webp';
import facebook from '../../assets/images/nugen-asset/facebook.webp';
import youtube from '../../assets/images/nugen-asset/youtubeIcon.webp';
import tiktok from '../../assets/images/nugen-asset/tiktokicon.webp';
import insta from '../../assets/images/nugen-asset/insta.webp';
import emailandlocation from '../../assets/images/nugen-asset/emailandlocation.webp';
import { useRef, useImperativeHandle, forwardRef, useState, useEffect } from "react";
import prdImg5 from '../../assets/images/nugen-asset/productImg5.webp';
import forestBg from '../../assets/images/background/forestBg.webp';
import { useIntl } from "react-intl";
import { EffectNumber, EffectString, ParallaxBanner, ParallaxBannerLayer } from "react-scroll-parallax";
interface Props {
    refProduct: any;
    refIngredientBg: any;
    refVMission: any;
    phoneView: any;
    windowDimension: any;
    parallaxView: any;
}

const footerBgAnimate: { size: any, x: EffectString, y: EffectString, scale?: EffectNumber, opacity?: EffectNumber, aspectRatio?: string }[] = [
    { size: 'large', x: ['0px', '0px'], y: ['0%', '100%'], scale: [1, 1], opacity: [-1, 4], aspectRatio: '1' },
    { size: 'medium', x: ['0px', '0px'], y: ['0%', '100%'], scale: [1, 1], opacity: [-1, 6], aspectRatio: '1' },
    { size: 'small', x: ['0px', '0px'], y: ['0%', '100%'], scale: [1.5, 1.5], opacity: [-1, 6], aspectRatio: '1/1.2' },
    { size: 'xsmall', x: ['0px', '0px'], y: ['0%', '100%'], scale: [1.5, 1.5], opacity: [-1, 6], aspectRatio: '1/1.5' },
    { size: 'xxsmall', x: ['0px', '0px'], y: ['0%', '55%'], scale: [1.5, 1.5], opacity: [0, 5], aspectRatio: '1/1.9' },
    { size: 'xxxsmall', x: ['0px', '0px'], y: ['0%', '30%'], scale: [1.5, 1.5], opacity: [0, 5], aspectRatio: '1/1.9' },
]

const footerBoxAnimate: { size: any, x: EffectString, y: EffectString, scale?: EffectNumber }[] = [
    { size: 'large', x: ['0px', '0px'], y: ['0px', '0px'], scale: [1, 1] },
    { size: 'medium', x: ['0px', '0px'], y: ['0px', '0px'], scale: [1, 1] },
    { size: 'small', x: ['0px', '0px'], y: ['0px', '0px'], scale: [1, 1] },
    { size: 'xsmall', x: ['0px', '0px'], y: ['0px', '0px'], scale: [1, 1] },
    { size: 'xxsmall', x: ['0px', '0px'], y: ['0px', '0px'], scale: [1, 1] },
    { size: 'xxxsmall', x: ['0px', '0px'], y: ['0px', '0px'], scale: [1, 1] },
]

const footerPrdImgAnimate: { size: any, x: EffectString, y: EffectString, scale?: EffectNumber, opacity?: EffectNumber }[] = [
    { size: 'large', x: ['0px', '0px'], y: ['5%', '25%'], scale: [0.4, 0.3], opacity: [0, 6] },
    { size: 'medium', x: ['0px', '0px'], y: ['5%', '12%'], scale: [0.4, 0.3], opacity: [0, 6] },
    { size: 'small', x: ['0px', '0px'], y: ['-18%', '-10%'], scale: [0.5, 0.4], opacity: [0, 6] },
    { size: 'xsmall', x: ['0px', '0px'], y: ['-15%', '-10%'], scale: [0.6, 0.45], opacity: [0, 6] },
    { size: 'xxsmall', x: ['0px', '0px'], y: ['-15%', '-15%'], scale: [0.8, 0.7], opacity: [0, 6] },
    { size: 'xxxsmall', x: ['0px', '0px'], y: ['-25%', '-20%'], scale: [0.8, 0.7], opacity: [0, 6] },
]


const Footer = forwardRef(({ refProduct, refIngredientBg, refVMission, windowDimension, parallaxView }: Props, ref) => {
    const intl = useIntl();
    const [bgSetting, setBgSetting] = useState<any>({ size: 'large', x: ['0px', '0px'], y: ['0px', '0px'], scale: [0, 0] });
    const [boxSetting, setBoxSetting] = useState<any>({ size: 'large', x: ['0px', '0px'], y: ['0px', '0px'], scale: [0, 0] });
    const [prdSetting, setPrdSetting] = useState<any>({ size: 'large', x: ['0px', '0px'], y: ['0px', '0px'], scale: [0, 0], opacity: [0, 0] });
    const currentYear = new Date().getFullYear();

    enum SocialType {
        FACEBOOK = 'FACEBOOK',
        INSTAGRAM = 'INSTAGRAM',
        YOUTUBE = 'YOUTUBE',
        TIKTOK = 'TIKTOK'
    }

    useEffect(() => {
        const useSize = footerBgAnimate.filter(x => x.size === windowDimension);
        const useSize2 = footerBoxAnimate.filter(x => x.size === windowDimension);
        const useSize3 = footerPrdImgAnimate.filter(x => x.size === windowDimension);
        setBgSetting(useSize[0]);
        setBoxSetting(useSize2[0]);
        setPrdSetting(useSize3[0]);
    }, [windowDimension]);

    const footerRef = useRef<null | HTMLDivElement>(null);
    useImperativeHandle(ref, () => ({
        scrollIntoView: () => {
            footerRef!.current!.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    }));

    const redirectTo = (type: any) => {
        let url = 'nugenlegacy.com';
        switch (type) {
            case SocialType.FACEBOOK:
                url = 'https://www.facebook.com/profile.php?id=100080441903706';
                break;
            case SocialType.INSTAGRAM:
                url = 'https://www.instagram.com/nugenlegacy/';
                break;
            case SocialType.YOUTUBE:
                url = 'https://www.youtube.com/channel/UCd-n_9Cwkyp7zpmoY2ecBJA';
                break;
            case SocialType.TIKTOK:
                url = 'https://www.tiktok.com/@ngl_official1';
                break;
        }
        window.open(url, '_blank', 'noopener,noreferrer');
    }

    const menuNav = (location: string) => {
        if (location === 'product') {
            if (refProduct.current) {
                refProduct.current.scrollIntoView();
            }
        }
        if (location === 'ingredient') {
            if (refIngredientBg.current) {
                refIngredientBg.current.scrollIntoView();
            }
        }
        if (location === 'aboutUs') {
            if (refVMission.current) {
                refVMission.current.scrollIntoView();
            }
        }
    }

    return (
        <>
            {
                parallaxView === false ?
                    <>
                        <div className="footer-container" ref={footerRef}>
                            <div className="footer-background">
                                <img alt="nugen-product" className="footer-product" src={Product} />
                                <div className="footer-blur-background">
                                    <div className="footer-details">
                                        <div className="footer-icon">
                                            <img className='footer-icon-logo' alt="logosmall" src={logosmall} />
                                        </div>

                                        <div className="footer-contact">
                                            <div className="footer-contact-details">
                                                <div>
                                                    nugen-info@nugen.com
                                                </div>
                                                <div className='footer-phone'>
                                                    03-6158 1199
                                                </div>
                                                <div>
                                                    <div>No, 2-3, Jalan PJU 5/11,</div>
                                                    <div>Dataran Sunway Kota Damansara,</div>
                                                    <div>47810 Petaling Jaya.</div>
                                                </div>
                                            </div>
                                            <img alt="nugen" src={emailandlocation} />
                                        </div>

                                        <div className="footer-icon">
                                            <div className='footer-icon-social'>
                                                <img style={{ marginLeft: "0px" }} alt="youtube" src={youtube} onClick={() => redirectTo(SocialType.YOUTUBE)} />
                                                <img alt="insta" src={insta} onClick={() => redirectTo(SocialType.INSTAGRAM)} />
                                                <img alt="facebook" src={facebook} onClick={() => redirectTo(SocialType.FACEBOOK)} />
                                                <img alt="tiktok" src={tiktok} onClick={() => redirectTo(SocialType.TIKTOK)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="footer-link">
                                        <p onClick={() => menuNav('product')}>{intl.formatMessage({ id: "PRODUCT" })}</p>
                                        <p onClick={() => menuNav('ingredient')}>{intl.formatMessage({ id: "INGREDIENT" })}</p>
                                        <p onClick={() => menuNav('aboutUs')}>{intl.formatMessage({ id: "ABOUT_US" })}</p>
                                    </div>
                                    <div className="footer-copyright">
                                        <p>Copyright © {currentYear} Nugen Legacy Powered by Nugen Legacy</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <div className="parallaxContainerP7" ref={footerRef}>
                            <ParallaxBanner style={{ aspectRatio: bgSetting.aspectRatio, position: "absolute", bottom: "0%", display: "flex", justifyContent: "flex-end", flexDirection: "column", alignItems: "center" }}>
                                <ParallaxBannerLayer className="footerForestDiv"
                                    image={forestBg}
                                    speed={-10}
                                    easing="easeInQuad"
                                    scale={bgSetting.scale}
                                    opacity={bgSetting.opacity}
                                    translateY={bgSetting.y}
                                ></ParallaxBannerLayer>
                                <ParallaxBannerLayer className="footerBlurDiv"
                                    speed={-10}
                                    easing="easeInQuad"
                                    translateY={boxSetting.y}
                                    children={
                                        <>
                                            {
                                                windowDimension === 'large' || windowDimension === 'medium' ?
                                                    <>
                                                        <div className="footerBox">
                                                            <div className="footer-details">
                                                                <div className="footer-icon">
                                                                    <img className='footer-icon-logo' alt="logosmall" src={logosmall} />
                                                                    <div className='footer-icon-social'>
                                                                        <img style={{ marginLeft: "0px" }} alt="youtube" src={youtube} onClick={() => redirectTo(SocialType.YOUTUBE)} />
                                                                        <img alt="insta" src={insta} onClick={() => redirectTo(SocialType.INSTAGRAM)} />
                                                                        <img alt="facebook" src={facebook} onClick={() => redirectTo(SocialType.FACEBOOK)} />
                                                                        <img alt="tiktok" src={tiktok} onClick={() => redirectTo(SocialType.TIKTOK)} />
                                                                    </div>
                                                                </div>
                                                                <div className="footer-contact">
                                                                    <div className="footer-contact-details">
                                                                        <div>
                                                                            nugen-info@nugen.com
                                                                        </div>
                                                                        <div className='footer-phone'>
                                                                            03-6158 1199
                                                                        </div>
                                                                        <div>
                                                                            <div>No, 2-3, Jalan PJU 5/11,</div>
                                                                            <div>Dataran Sunway Kota Damansara,</div>
                                                                            <div>47810 Petaling Jaya.</div>
                                                                        </div>
                                                                    </div>
                                                                    <img alt="nugen" src={emailandlocation} />
                                                                </div>
                                                            </div>
                                                            <div className="footer-link">
                                                                <p onClick={() => menuNav('product')}>{intl.formatMessage({ id: "PRODUCT" })}</p>
                                                                <p onClick={() => menuNav('ingredient')}>{intl.formatMessage({ id: "INGREDIENT" })}</p>
                                                                <p onClick={() => menuNav('aboutUs')}>{intl.formatMessage({ id: "ABOUT_US" })}</p>
                                                            </div>
                                                            <div className="footer-copyright">
                                                                <p>Copyright © {currentYear} Nugen Legacy Sdn. Bhd. (1450064-H) Powered by Sigma Coders Sdn. Bhd.</p>
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <div className="footerBox">
                                                            <div className="footer-details">
                                                                <div className="footer-contact">
                                                                    <div className="footer-contact-details">
                                                                        <div>
                                                                            nugen-info@nugen.com
                                                                        </div>
                                                                        <div className='footer-phone'>
                                                                            03-6158 1199
                                                                        </div>
                                                                        <div>
                                                                            <div>No, 2-3, Jalan PJU 5/11,</div>
                                                                            <div>Dataran Sunway Kota Damansara,</div>
                                                                            <div>47810 Petaling Jaya.</div>
                                                                        </div>
                                                                    </div>
                                                                    <img alt="nugen" src={emailandlocation} />
                                                                </div>

                                                                <div className="footer-icon">
                                                                    <img className='footer-icon-logo' alt="logosmall" src={logosmall} />
                                                                    <div className='footer-icon-social'>
                                                                        <img style={{ marginLeft: "0px" }} alt="youtube" src={youtube} onClick={() => redirectTo(SocialType.YOUTUBE)} />
                                                                        <img alt="insta" src={insta} onClick={() => redirectTo(SocialType.INSTAGRAM)} />
                                                                        <img alt="facebook" src={facebook} onClick={() => redirectTo(SocialType.FACEBOOK)} />
                                                                        <img alt="tiktok" src={tiktok} onClick={() => redirectTo(SocialType.TIKTOK)} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="footer-link">
                                                                <p onClick={() => menuNav('product')}>{intl.formatMessage({ id: "PRODUCT" })}</p>
                                                                <p onClick={() => menuNav('ingredient')}>{intl.formatMessage({ id: "INGREDIENT" })}</p>
                                                                <p onClick={() => menuNav('aboutUs')}>{intl.formatMessage({ id: "ABOUT_US" })}</p>
                                                            </div>
                                                            <div className="footer-copyright">
                                                                <p>Copyright © {currentYear} Nugen Legacy Sdn. Bhd. (1450064-H)<br/>
                                                                Powered by Sigma Coders Sdn. Bhd.</p>                            
                                                            </div>
                                                        </div>
                                                    </>
                                            }
                                        </>
                                    }
                                />
                                <ParallaxBannerLayer className="prdImg5Div"
                                    image={prdImg5}
                                    speed={-20}
                                    scale={prdSetting.scale}
                                    opacity={prdSetting.opacity}
                                    translateY={prdSetting.y}
                                    easing="easeOutQuad"
                                />

                            </ParallaxBanner>
                        </div>
                    </>
            }
        </>
    )
})

export default Footer;