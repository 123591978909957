import prdImg4 from '../../assets/images/nugen-asset/productImg4.webp';
import cloudJoinNew from '../../assets/images/background/cloudJoinNew.webp';
import "./CompanyProfile.less";
import "./CompanyProfileMB.less";
import { useIntl } from "react-intl";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { EffectNumber, EffectString, Parallax, ParallaxBanner, ParallaxBannerLayer } from "react-scroll-parallax";
interface Props {
    phoneView: any;
    windowDimension: any;
    parallaxView: any;
}

const cloudAnimate: { size: any, x: EffectString, y: EffectString, scale?: EffectNumber }[] = [
    { size: 'large', x: ['0px', '0px'], y: ['-15%', '-25%'], scale: [0, 0] },
    { size: 'medium', x: ['0px', '0px'], y: ['-20%', '-30%'], scale: [0, 0] },
    { size: 'small', x: ['0px', '0px'], y: ['-25%', '-35%'], scale: [0, 0] },
    { size: 'xsmall', x: ['0px', '0px'], y: ['-30%', '-40%'], scale: [0, 0] },
    { size: 'xxsmall', x: ['0px', '0px'], y: ['-30%', '-40%'], scale: [0, 0] },
    { size: 'xxxsmall', x: ['0px', '0px'], y: ['-30%', '-40%'], scale: [0, 0] },
]

const prdImgAnimate: { size: any, x: EffectString, y: EffectString, scale?: EffectNumber, opacity?: EffectNumber }[] = [
    { size: 'large', x: ['0px', '0px'], y: ['0%', '25%'], scale: [0.8, 0.6], opacity: [0, 3] },
    { size: 'medium', x: ['0px', '0px'], y: ['0%', '25%'], scale: [0.8, 0.6], opacity: [0, 3] },
    { size: 'small', x: ['0px', '0px'], y: ['-5%', '20%'], scale: [0.8, 0.6], opacity: [0, 3] },
    { size: 'xsmall', x: ['0px', '0px'], y: ['-15%', '20%'], scale: [1, 0.8], opacity: [0, 3] },
    { size: 'xxsmall', x: ['0px', '0px'], y: ['-15%', '20%'], scale: [1.3, 1.1], opacity: [0, 3] },
    { size: 'xxxsmall', x: ['0px', '0px'], y: ['-15%', '20%'], scale: [1.3, 1.1], opacity: [0, 3] },
]

const visionAnimate: { size: any, x: EffectString, y: EffectString, scale?: EffectNumber, opacity?: EffectNumber }[] = [
    { size: 'large', x: ['100%', '0%'], y: ['105%', '105%'], scale: [0.6, 0.4], opacity: [0, 3] },
    { size: 'medium', x: ['100%', '0%'], y: ['105%', '105%'], scale: [0.6, 0.4], opacity: [0, 3] },
    { size: 'small', x: ['100%', '0%'], y: ['90%', '90%'], scale: [0.6, 0.4], opacity: [0, 3] },
    { size: 'xsmall', x: ['100%', '0%'], y: ['90%', '90%'], scale: [0.6, 0.4], opacity: [0, 3] },
    { size: 'xxsmall', x: ['100%', '0%'], y: ['90%', '90%'], scale: [0.6, 0.6], opacity: [0, 3] },
    { size: 'xxxsmall', x: ['100%', '0%'], y: ['90%', '90%'], scale: [0.6, 0.6], opacity: [0, 3] },
]

const missionAnimate: { size: any, x: EffectString, y: EffectString, scale?: EffectNumber, opacity?: EffectNumber }[] = [
    { size: 'large', x: ['-100%', '0%'], y: ['120%', '120%'], scale: [0.6, 0.4], opacity: [0, 3] },
    { size: 'medium', x: ['-100%', '0%'], y: ['120%', '120%'], scale: [0.6, 0.4], opacity: [0, 3] },
    { size: 'small', x: ['-100%', '0%'], y: ['105%', '105%'], scale: [0.6, 0.4], opacity: [0, 3] },
    { size: 'xsmall', x: ['-100%', '0%'], y: ['105%', '105%'], scale: [0.6, 0.6], opacity: [0, 3] },
    { size: 'xxsmall', x: ['-100%', '0%'], y: ['110%', '110%'], scale: [0.6, 0.6], opacity: [0, 3] },
    { size: 'xxxsmall', x: ['-100%', '0%'], y: ['110%', '110%'], scale: [0.6, 0.6], opacity: [0, 3] },
]

const VMission = forwardRef(({ phoneView, windowDimension, parallaxView }: Props, ref) => {
    const intl = useIntl();
    const [cloudSetting, setCloudSetting] = useState<any>({ size: 'large', x: ['0px', '0px'], y: ['0px', '0px'], scale: [0, 0] });
    const [prdImgSetting, setPrdImgSetting] = useState<any>({ size: 'large', x: ['0px', '0px'], y: ['0px', '0px'], scale: [0, 0] });
    const [visionSetting, setVisionSetting] = useState<any>({ size: 'large', x: ['0px', '0px'], y: ['0px', '0px'], scale: [0, 0] });
    const [missionSetting, setMissionSetting] = useState<any>({ size: 'large', x: ['0px', '0px'], y: ['0px', '0px'], scale: [0, 0] });
    const [targetElement, setTarget] = useState<HTMLDivElement | null>(null);
    const targetRef = useRef<null | HTMLDivElement>(null);
    const vmissionRef = useRef<null | HTMLDivElement>(null);
    useImperativeHandle(ref, () => ({
        scrollIntoView: () => {
            vmissionRef!.current!.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    }));

    useEffect(() => {
        const useSize = cloudAnimate.filter(x => x.size === windowDimension);
        const useSize2 = prdImgAnimate.filter(x => x.size === windowDimension);
        const useSize3 = visionAnimate.filter(x => x.size === windowDimension);
        const useSize4 = missionAnimate.filter(x => x.size === windowDimension);
        setCloudSetting(useSize[0]);
        setPrdImgSetting(useSize2[0]);
        setVisionSetting(useSize3[0]);
        setMissionSetting(useSize4[0]);
    }, [windowDimension]);


    return (
        <>
            {
                parallaxView === false ?
                    <div className="vmContentWrapper" ref={vmissionRef}>
                        <div className="vmContentSectionContainer">
                            <img alt="prd4" className="vmProduct" src={prdImg4} />

                            <div className="vmTextBox">
                                <p className="fontSizing" style={{ fontWeight: 700 }}>{intl.formatMessage({ id: "VISION_TITLE" })}</p>
                                <p className="fontSizing" >{intl.formatMessage({ id: "VISION_1" })}</p>
                                <p className="fontSizing" style={{ fontWeight: 700 }}>{intl.formatMessage({ id: "MISSION_TITLE" })}</p>
                                <p className="fontSizing" >{intl.formatMessage({ id: "MISSION_1" })}</p>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="parallaxContainerP6" ref={vmissionRef}>
                        <ParallaxBanner style={{ aspectRatio: '1/0.5', position: "unset" }}>
                            <Parallax style={{ width: '100%', height: '50%', position: 'sticky', marginTop: '80vh' }}>
                                {/* control animation start end */}
                                <div ref={el => { targetRef.current = el; setTarget(el); }}></div>
                            </Parallax>
                            <ParallaxBannerLayer className="cloudJoinDiv"
                                image={cloudJoinNew}
                                speed={-20}
                                translateY={cloudSetting.y}
                                easing="easeOutQuad"
                            />
                            {
                                targetElement !== null ?
                                    <>
                                        <ParallaxBannerLayer className="prdImg4Div"
                                            targetElement={targetElement}
                                            image={prdImg4}
                                            speed={-20}
                                            scale={prdImgSetting.scale}
                                            opacity={prdImgSetting.opacity}
                                            translateY={prdImgSetting.y}
                                            easing="easeInQuad"
                                        />
                                        <ParallaxBannerLayer className="visionBoxDiv"
                                            targetElement={targetElement}
                                            speed={10}
                                            translateY={visionSetting.y}
                                            translateX={visionSetting.x}
                                            opacity={[0, 1]}
                                            easing="easeInQuad"
                                            children={
                                                (
                                                    <div className="textBox2">
                                                        <div className="prdContentBox2">
                                                            <div className="prdContentText2">
                                                                <p className="fontSizing" style={{ fontWeight: 700 }}>{intl.formatMessage({ id: "VISION_TITLE" })}</p>
                                                                <p className="fontSizing" >{intl.formatMessage({ id: "VISION_1" })}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        ></ParallaxBannerLayer>
                                        <ParallaxBannerLayer className="missionBoxDiv"
                                            targetElement={targetElement}
                                            speed={-10}
                                            translateY={missionSetting.y}
                                            translateX={missionSetting.x}
                                            opacity={[0, 1]}
                                            easing="easeInQuad"
                                            children={
                                                (
                                                    <div className="textBox2">
                                                        <div className="prdContentBox2">
                                                            <div className="prdContentText2">
                                                                <p className="fontSizing" style={{ fontWeight: 700 }}>{intl.formatMessage({ id: "MISSION_TITLE" })}</p>
                                                                <p className="fontSizing" >{intl.formatMessage({ id: "MISSION_1" })}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        ></ParallaxBannerLayer>
                                    </>
                                    :
                                    <></>
                            }
                        </ParallaxBanner>
                    </div>
            }

        </>
    )
})

export default VMission;