import orangeClip from '../../assets/images/nugen-asset/orange2.webp';
import blueberryClip from '../../assets/images/nugen-asset/blueberry2.webp';
import pomegranateClip from '../../assets/images/nugen-asset/pomegranate1.webp';
import nucleotideClip from '../../assets/images/nugen-asset/nucleotide.webp';
import acaiberryClip from '../../assets/images/nugen-asset/acaiberry.webp';
import atpClip from '../../assets/images/nugen-asset/atp.webp';
import AscorbicAcidClip from '../../assets/images/nugen-asset/AscorbicAcid.webp';
import ingredientBg from '../../assets/images/background/ingredientPlainBg.webp';
import ingredientBg2 from '../../assets/images/background/ingredientBgImg.webp';
import ladybird from '../../assets/images/nugen-asset/ingLadyBird.webp';
import productOpen from '../../assets/images/nugen-asset/ingPrdImg.webp';
import "./IngredientBg.less";

import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import Slider from 'react-slick';
import { useIntl } from 'react-intl';
import { EffectNumber, EffectString, Parallax, ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax';
interface Props {
    phoneView: any;
    windowDimension: any;
    parallaxView: any;
}

const bgAnimate: { size: any, x: EffectString, y: EffectString, scale?: EffectNumber }[] = [
    { size: 'large', x: ['0%', '0%'], y: ['-30%', '-5%'], scale: [1, 1] },
    { size: 'medium', x: ['0%', '0%'], y: ['-30%', '-5%'], scale: [1, 1] },
    { size: 'small', x: ['0%', '0%'], y: ['-30%', '-5%'], scale: [1, 1] },
    { size: 'xsmall', x: ['0%', '0%'], y: ['-30%', '-5%'], scale: [1, 1] },
    { size: 'xxsmall', x: ['0%', '0%'], y: ['-30%', '-5%'], scale: [1, 1] },
    { size: 'xxxsmall', x: ['0%', '0%'], y: ['-30%', '-5%'], scale: [1, 1] },
]

const carouselAnimate: { size: any, x: EffectString, y: EffectString, scale?: EffectNumber }[] = [
    { size: 'large', x: ['0px', '0px'], y: ['10%', '10%'], scale: [0.9, 1] },
    { size: 'medium', x: ['0px', '0px'], y: ['10%', '10%'], scale: [0.9, 1] },
    { size: 'small', x: ['0px', '0px'], y: ['10%', '10%'], scale: [0.9, 1] },
    { size: 'xsmall', x: ['0px', '0px'], y: ['10%', '10%'], scale: [0.9, 1] },
    { size: 'xxsmall', x: ['0px', '0px'], y: ['10%', '10%'], scale: [0.9, 1] },
    { size: 'xxxsmall', x: ['0px', '0px'], y: ['10%', '10%'], scale: [0.9, 1] },
]

const prdAnimate: { size: any, x: EffectString, y: EffectString, scale?: EffectNumber, opacity?: EffectNumber }[] = [
    { size: 'large', x: ['-60%', '-35%'], y: ['0%', '20%'], scale: [0.4, 0.4], opacity: [-1, 4] },
    { size: 'medium', x: ['-60%', '-35%'], y: ['0%', '20%'], scale: [0.5, 0.5], opacity: [-1, 4] },
    { size: 'small', x: ['-60%', '-35%'], y: ['0%', '20%'], scale: [0.5, 0.5], opacity: [-1, 4] },
    { size: 'xsmall', x: ['-60%', '-35%'], y: ['0%', '20%'], scale: [0.65, 0.65], opacity: [-1, 4] },
    { size: 'xxsmall', x: ['-60%', '-35%'], y: ['0%', '20%'], scale: [1, 1], opacity: [-1, 4] },
    { size: 'xxxsmall', x: ['-60%', '-35%'], y: ['0%', '20%'], scale: [1, 1], opacity: [-1, 4] },
]

const ladybirdAnimate: { size: any, x: EffectString, y: EffectString, scale?: EffectNumber, opacity?: EffectNumber }[] = [
    { size: 'large', x: ['60%', '40%'], y: ['30%', '10%'], scale: [0.3, 0.3], opacity: [-2, 4] },
    { size: 'medium', x: ['60%', '40%'], y: ['30%', '10%'], scale: [0.3, 0.3], opacity: [-2, 4] },
    { size: 'small', x: ['60%', '40%'], y: ['30%', '10%'], scale: [0.3, 0.3], opacity: [-2, 4] },
    { size: 'xsmall', x: ['60%', '40%'], y: ['30%', '10%'], scale: [0.4, 0.4], opacity: [-2, 4] },
    { size: 'xxsmall', x: ['60%', '40%'], y: ['30%', '10%'], scale: [0.6, 0.6], opacity: [-2, 4] },
    { size: 'xxxsmall', x: ['60%', '40%'], y: ['30%', '10%'], scale: [0.6, 0.6], opacity: [-2, 4] },
]

function SampleNextArrow(props: { className?: any; style?: any; onClick?: any; }) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "green", borderRadius: "100%" }}
            onClick={onClick}
        />
    );
}

function SamplePrevArrow(props: { className?: any; style?: any; onClick?: any; }) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "green", borderRadius: "100%" }}
            onClick={onClick}
        />
    );
}

const IngredientBg = forwardRef(({ phoneView, windowDimension, parallaxView }: Props, ref) => {
    const intl = useIntl();
    const [bgSetting, setBgSetting] = useState<any>({ size: 'large', x: ['0px', '0px'], y: ['0px', '0px'], scale: [0, 0] });
    const [carouselSetting, setCarouselSetting] = useState<any>({ size: 'large', x: ['0px', '0px'], y: ['0px', '0px'], scale: [0, 0] });
    const [prdSetting, setPrdSetting] = useState<any>({ size: 'large', x: ['0px', '0px'], y: ['0px', '0px'], scale: [0, 0], opacity: [0, 0] });
    const [ladybirdSetting, setLadybirdSetting] = useState<any>({ size: 'large', x: ['0px', '0px'], y: ['0px', '0px'], scale: [0, 0], opacity: [0, 0] });
    const [targetElement, setTarget] = useState<HTMLDivElement | null>(null);
    const targetRef = useRef<null | HTMLDivElement>(null);

    const settings = {
        className: "slider variable-width ingCarousel",
        centerMode: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        variableWidth: true,
        adaptiveHeight: true,
        focusOnSelect: true,
        dots: false,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1440,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                }
            },
        ]
    };

    const ingredientRef = useRef<null | HTMLDivElement>(null);
    useImperativeHandle(ref, () => ({
        scrollIntoView: () => {
            ingredientRef!.current!.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    }));

    useEffect(() => {
        const useSize = bgAnimate.filter(x => x.size === windowDimension);
        const useSize2 = carouselAnimate.filter(x => x.size === windowDimension);
        const useSize4 = prdAnimate.filter(x => x.size === windowDimension);
        const useSize5 = ladybirdAnimate.filter(x => x.size === windowDimension);
        setBgSetting(useSize[0]);
        setCarouselSetting(useSize2[0]);
        setPrdSetting(useSize4[0]);
        setLadybirdSetting(useSize5[0]);
    }, [windowDimension]);

    return (
        <>
            {
                parallaxView === false ?
                    <div className="ingredientSection1" >
                        <div className="ingredientSectionContainer">
                            <div className="ingContentSection">
                                <div className="ingCarouselWrapper" ref={ingredientRef}>
                                    <div>
                                        <Slider {...settings}>
                                            <div style={{ width: 300 }}>
                                                <div className="contentNucleo">
                                                    <img alt="ingNucleo" className="ingNucleo" src={nucleotideClip} />
                                                </div>
                                                <div className="ingPrdTextBox">
                                                    <div style={{ zIndex: 90, position: "absolute" }}>
                                                        <h2 className="titleSizing">{intl.formatMessage({ id: "ING_NUCLEOTIDE" })}</h2>
                                                        <p className="ingPara fontSizing">{intl.formatMessage({ id: "ING_NUCLEOTIDE_DTL" })}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ width: 300 }}>
                                                <div className="contentATP">
                                                    <img alt="ingATP" className="ingATP" src={atpClip} />
                                                </div>
                                                <div className="ingPrdTextBox">
                                                    <div style={{ zIndex: 90, position: "absolute" }}>
                                                        <h2 className="titleSizing">{intl.formatMessage({ id: "ING_ATP" })}</h2>
                                                        <p className="ingPara fontSizing">{intl.formatMessage({ id: "ING_ATP_DTL" })}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ width: 300 }}>
                                                <div className="contentAscorbicAcid">
                                                    <img alt="ingAscorbicAcid" className="ingAscorbicAcid" src={AscorbicAcidClip} />
                                                </div>
                                                <div className="ingPrdTextBox">
                                                    <div style={{ zIndex: 90, position: "absolute" }}>
                                                        <h2 className="titleSizing">{intl.formatMessage({ id: "ING_ASCORBICACID" })}</h2>
                                                        <p className="ingPara fontSizing">{intl.formatMessage({ id: "ING_ASCORBICACID_DTL" })}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ width: 300 }}>
                                                <div className="contentOrange">
                                                    <img alt="ingOrange" className="ingOrange" src={orangeClip} />
                                                </div>
                                                <div className="ingPrdTextBox">
                                                    <div style={{ zIndex: 90, position: "absolute" }}>
                                                        <h2 className="titleSizing">{intl.formatMessage({ id: "ING_ORANGE" })}</h2>
                                                        <p className="ingPara fontSizing">{intl.formatMessage({ id: "ING_ORANGE_DTL" })}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ width: 300 }}>
                                                <div className="contentBlueberry">
                                                    <img alt="ingBlueberry" className="ingBlueberry" src={blueberryClip} />
                                                </div>
                                                <div className="ingPrdTextBox">
                                                    <div style={{ zIndex: 90, position: "absolute" }}>
                                                        <h2 className="titleSizing">{intl.formatMessage({ id: "ING_BLUEBERRY" })}</h2>
                                                        <p className="ingPara fontSizing">{intl.formatMessage({ id: "ING_BLUEBERRY_DTL" })}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ width: 300 }}>
                                                <div className="contentPomeg">
                                                    <img alt="ingPomeg" className="ingPomeg" src={pomegranateClip} />
                                                </div>
                                                <div className="ingPrdTextBox">
                                                    <div style={{ zIndex: 90, position: "absolute" }}>
                                                        <h2 className="titleSizing">{intl.formatMessage({ id: "ING_POMEGRANATE" })}</h2>
                                                        <p className="ingPara fontSizing">{intl.formatMessage({ id: "ING_POMEGRANATE_DTL" })}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ width: 300 }}>
                                                <div className="contentAcai">
                                                    <img alt="ingAcai" className="ingAcai" src={acaiberryClip} />
                                                </div>
                                                <div className="ingPrdTextBox">
                                                    <div style={{ zIndex: 90, position: "absolute" }}>
                                                        <h2 className="titleSizing">{intl.formatMessage({ id: "ING_ACAIBERRY" })}</h2>
                                                        <p className="ingPara fontSizing">{intl.formatMessage({ id: "ING_ACAIBERRY_DTL" })}</p>
                                                    </div>
                                                </div>
                                            </div>

                                        </Slider>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div style={{ overflow: "hidden", width: "100%", marginTop: "-10%" }}>
                            <img alt="ingredientBgImg3" className="ingredientBgImg3" src={ingredientBg2} />
                        </div>
                    </div>
                    :
                    <div className="parallaxContainerP5">
                        <ParallaxBanner style={{ aspectRatio: '1/0.5', position: "unset" }}>
                            <Parallax style={{ width: '100%', height: '50%', position: 'sticky', marginTop: '30vh', zIndex: 3000 }}>
                                {/* control animation start end */}
                                <div ref={el => { targetRef.current = el; setTarget(el); }}></div>
                            </Parallax>
                            <ParallaxBannerLayer className="ingBgDiv"
                                image={ingredientBg}
                                speed={-10}
                                translateY={bgSetting.y}
                                scale={bgSetting.scale}
                                easing="easeOutQuad"
                            ></ParallaxBannerLayer>
                            <ParallaxBannerLayer className="ingCarouselDiv"
                                speed={10}
                                translateY={carouselSetting.y}
                                scale={carouselSetting.scale}
                                easing="easeOutQuad"
                                children={
                                    (
                                        <div className="carouselWrapper" ref={ingredientRef}>
                                            <Slider {...settings}>
                                                <div style={{ width: 300 }}>
                                                    <div className="nucleoWrapper">
                                                        <img alt="nucleoClip" className="nucleoClip" src={nucleotideClip} />
                                                    </div>
                                                    <div className="ingTextWrapper">
                                                        <div className="ingTextWrapper2">
                                                            <h2 className="titleSizing">{intl.formatMessage({ id: "ING_NUCLEOTIDE" })}</h2>
                                                            <p className="ingPara fontSizing">{intl.formatMessage({ id: "ING_NUCLEOTIDE_DTL" })}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{ width: 300 }}>
                                                    <div className="atpWrapper">
                                                        <img alt="atpClip" className="atpClip" src={atpClip} />
                                                    </div>
                                                    <div className="ingTextWrapper">
                                                        <div className="ingTextWrapper2">
                                                            <h2 className="titleSizing">{intl.formatMessage({ id: "ING_ATP" })}</h2>
                                                            <p className="ingPara fontSizing">{intl.formatMessage({ id: "ING_ATP_DTL" })}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{ width: 300 }}>
                                                    <div className="ascorbicWrapper">
                                                        <img alt="ascorbicClip" className="ascorbicClip" src={AscorbicAcidClip} />
                                                    </div>
                                                    <div className="ingTextWrapper">
                                                        <div className="ingTextWrapper2">
                                                            <h2 className="titleSizing">{intl.formatMessage({ id: "ING_ASCORBICACID" })}</h2>
                                                            <p className="ingPara fontSizing">{intl.formatMessage({ id: "ING_ASCORBICACID_DTL" })}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{ width: 300 }}>
                                                    <div className="orangeWrapper">
                                                        <img alt="orangeClip" className="orangeClip" src={orangeClip} />
                                                    </div>
                                                    <div className="ingTextWrapper">
                                                        <div className="ingTextWrapper2">
                                                            <h2 className="titleSizing">{intl.formatMessage({ id: "ING_ORANGE" })}</h2>
                                                            <p className="ingPara fontSizing">{intl.formatMessage({ id: "ING_ORANGE_DTL" })}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{ width: 300 }}>
                                                    <div className="blueberryWrapper">
                                                        <img alt="blueberryClip" className="blueberryClip" src={blueberryClip} />
                                                    </div>
                                                    <div className="ingTextWrapper">
                                                        <div className="ingTextWrapper2">
                                                            <h2 className="titleSizing">{intl.formatMessage({ id: "ING_BLUEBERRY" })}</h2>
                                                            <p className="ingPara fontSizing">{intl.formatMessage({ id: "ING_BLUEBERRY_DTL" })}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{ width: 300 }}>
                                                    <div className="pomegWrapper">
                                                        <img alt="pomegClip" className="pomegClip" src={pomegranateClip} />
                                                    </div>
                                                    <div className="ingTextWrapper">
                                                        <div className="ingTextWrapper2">
                                                            <h2 className="titleSizing">{intl.formatMessage({ id: "ING_POMEGRANATE" })}</h2>
                                                            <p className="ingPara fontSizing">{intl.formatMessage({ id: "ING_POMEGRANATE_DTL" })}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{ width: 300 }}>
                                                    <div className="acaiberryWrapper">
                                                        <img alt="acaiberryClip" className="acaiberryClip" src={acaiberryClip} />
                                                    </div>
                                                    <div className="ingTextWrapper">
                                                        <div className="ingTextWrapper2">
                                                            <h2 className="titleSizing">{intl.formatMessage({ id: "ING_ACAIBERRY" })}</h2>
                                                            <p className="ingPara fontSizing">{intl.formatMessage({ id: "ING_ACAIBERRY_DTL" })}</p>
                                                        </div>
                                                    </div>
                                                </div>


                                            </Slider>
                                        </div>
                                    )
                                }
                            ></ParallaxBannerLayer>
                            {
                                targetElement !== null ?
                                    <>
                                        <ParallaxBannerLayer className="ladybirdDiv"
                                            targetElement={targetElement}
                                            image={ladybird}
                                            speed={-20}
                                            scale={ladybirdSetting.scale}
                                            opacity={ladybirdSetting.opacity}
                                            translateX={ladybirdSetting.x}
                                            translateY={ladybirdSetting.y}
                                            easing="easeInOutQuad"
                                        />
                                        <ParallaxBannerLayer className="productOpenDiv"
                                            targetElement={targetElement}
                                            image={productOpen}
                                            speed={-10}
                                            scale={prdSetting.scale}
                                            opacity={prdSetting.opacity}
                                            translateX={prdSetting.x}
                                            translateY={prdSetting.y}
                                            easing="easeInOutQuad"
                                        />
                                    </>
                                    :
                                    <></>
                            }
                        </ParallaxBanner>
                    </div>}

        </>
    )
})

export default IngredientBg;