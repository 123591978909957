import appLogo from '../../assets/images/nugen-asset/nugenLogo.webp';
import appLogoSmall from '../../assets/images/nugen-asset/nugenLogoSmall.webp';
import cloud1 from '../../assets/images/background/headerCloud1.webp';
import cloudLeft from '../../assets/images/background/cloudLeft.webp';
import cloudRight from '../../assets/images/background/cloudRight2.webp';
import "./Header.less";
import LanguageSwitcher from '../../widget/LanguageSwitcher';

import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { useIntl } from 'react-intl';
import { EffectNumber, EffectString, ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax';
interface Props {
    phoneView: any;
    refHead: any;
    refFooter: any;
    refProduct: any;
    refProduct2: any;
    refProduct3: any;
    refIngredientBg: any;
    refVMission: any;
    parallaxView: any;
    windowDimension: any;
}

const cloud1Animate: { size: any, x: EffectString, y: EffectString, scale?: EffectNumber }[] = [
    { size: 'large', x: ['-20%', '-60%'], y: ['5%', '1%'], scale: [1, 0.3] },
    { size: 'medium', x: ['-20%', '-60%'], y: ['5%', '1%'], scale: [1, 0.3] },
    { size: 'small', x: ['-20%', '-60%'], y: ['-25%', '0%'], scale: [1, 0.3] },
    { size: 'xsmall', x: ['35%', '-80%'], y: ['-60%', '-10%'], scale: [1.5, 1] },
    { size: 'xxsmall', x: ['35%', '-80%'], y: ['-60%', '-10%'], scale: [1.5, 1] },
    { size: 'xxxsmall', x: ['35%', '-80%'], y: ['-60%', '-10%'], scale: [1.5, 1] },
]

const cloud2Animate: { size: any, x: EffectString, y: EffectString, scale?: EffectNumber }[] = [
    { size: 'large', x: ['20%', '60%'], y: ['-5%', '-5%'], scale: [1, 0.5] },
    { size: 'medium', x: ['20%', '60%'], y: ['-5%', '-5%'], scale: [1, 0.5] },
    { size: 'small', x: ['20%', '60%'], y: ['-25%', '0%'], scale: [1, 0.5] },
    { size: 'xsmall', x: ['-35%', '80%'], y: ['-25%', '10%'], scale: [1.5, 1] },
    { size: 'xxsmall', x: ['-35%', '80%'], y: ['-25%', '10%'], scale: [1.5, 1] },
    { size: 'xxxsmall', x: ['-35%', '80%'], y: ['-25%', '10%'], scale: [1.5, 1] },
]

const logoAnimate: { size: any, x?: EffectString, y: EffectString, scale?: EffectNumber }[] = [
    { size: 'large', y: ['-30%', '30%'], scale: [0.2, 1] },
    { size: 'medium', y: ['-40%', '20%'], scale: [0.2, 1] },
    { size: 'small', y: ['-50%', '10%'], scale: [0.2, 1] },
    { size: 'xsmall', y: ['-60%', '0%'], scale: [0.2, 1] },
    { size: 'xxsmall', y: ['-60%', '0%'], scale: [0.2, 1] },
    { size: 'xxxsmall', y: ['-60%', '0%'], scale: [0.2, 1] },
]

const Header = ({ phoneView, refHead, refFooter, refProduct, refProduct2, refProduct3, refIngredientBg, refVMission, windowDimension, parallaxView }: Props) => {
    const intl = useIntl();
    const [menuOpened, setMenuOpened] = useState<boolean>(false);
    const [logoPosition, setLogoPosition] = useState<boolean>(false);
    const [cloud1Setting, setCloud1Setting] = useState<any>({ size: 'large', x: ['0px', '0px'], y: ['0px', '0px'], scale: [0, 0] });
    const [cloud2Setting, setCloud2Setting] = useState<any>({ size: 'large', x: ['0px', '0px'], y: ['0px', '0px'], scale: [0, 0] });
    const [logoSetting, setLogoSetting] = useState<any>({ size: 'large', x: ['0px', '0px'], y: ['0px', '0px'], scale: [0, 0] });

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 650) {
                setLogoPosition(true)
            } else {
                setLogoPosition(false)
            }
        });
    }, []);

    useEffect(() => {
        const useSize = logoAnimate.filter(x => x.size === windowDimension);
        const useSize2 = cloud1Animate.filter(x => x.size === windowDimension);
        const useSize3 = cloud2Animate.filter(x => x.size === windowDimension);
        setLogoSetting(useSize[0]);
        setCloud1Setting(useSize2[0]);
        setCloud2Setting(useSize3[0]);
    }, [windowDimension]);

    const menuClick = () => {
        setMenuOpened(!menuOpened);
    }

    const menuNav = (location: string) => {
        if (location === 'contactUs') {
            if (refFooter.current) {
                refFooter.current.scrollIntoView();
            }
        }
        if (location === 'product') {
            if (refProduct.current) {
                refProduct.current.scrollIntoView();
            }
        }
        if (location === 'ingredient') {
            if (refIngredientBg.current) {
                refIngredientBg.current.scrollIntoView();
            }
        }
        if (location === 'aboutUs') {
            if (refVMission.current) {
                refVMission.current.scrollIntoView();
            }
        }
        if (location === 'home') {
            if (refHead.current) {
                refHead.current.scrollIntoView({ behavior: "smooth", block: "start" });
            }
        }
        if (phoneView === true) {
            setMenuOpened(!menuOpened);
        }
    }

    return (
        <>
            {/* Menu Backdrop */}
            {
                menuOpened === true ?
                    <div className="backdropMenu">
                        <div className="menuList">
                            <img alt="logoImageSmall" className="logoImageSmall" src={appLogo} />
                            <p className="textButton" onClick={() => menuNav('home')}>{intl.formatMessage({ id: "HOME" })}</p>
                            <p className="textButton" onClick={() => menuNav('product')}>{intl.formatMessage({ id: "PRODUCT" })}</p>
                            <p className="textButton" onClick={() => menuNav('ingredient')}>{intl.formatMessage({ id: "INGREDIENT" })}</p>
                            <p className="textButton" onClick={() => menuNav('aboutUs')}>{intl.formatMessage({ id: "ABOUT_US" })}</p>
                            <p className="textButton" onClick={() => menuNav('contactUs')}>{intl.formatMessage({ id: "CONTACT_US" })}</p>
                            <LanguageSwitcher refHead={refHead}></LanguageSwitcher>
                        </div>
                    </div>
                    :
                    null
            }

            {/* Menu Button / Header */}
            {
                phoneView === true ?
                    <div className="mbButtonContainer">
                        <button name="menuButton" className="buttonStyle" onClick={() => menuClick()}><FontAwesomeIcon icon={menuOpened === false ? faBars : faXmark} fontSize={20} /></button>
                    </div>
                    :
                    <div className="headerMenuBar">
                        <div style={{ padding: "15px 25px", minWidth: "180px" }}>
                            {
                                logoPosition === true ?
                                    <div className="headerLogo">
                                        <img alt="logoShort" style={{ width: "25%" }} src={appLogoSmall} />
                                    </div>
                                    :
                                    null
                            }
                        </div>
                        <div style={{ padding: "0px 25px", minWidth: "450px", zIndex: 99999 }}>
                            <p>
                                <span onClick={() => menuNav('home')}>{intl.formatMessage({ id: "HOME" })}</span>
                                <span onClick={() => menuNav('product')}>{intl.formatMessage({ id: "PRODUCT" })}</span>
                                <span onClick={() => menuNav('ingredient')}>{intl.formatMessage({ id: "INGREDIENT" })}</span>
                                <span onClick={() => menuNav('aboutUs')}>{intl.formatMessage({ id: "ABOUT_US" })}</span>
                                <span onClick={() => menuNav('contactUs')}>{intl.formatMessage({ id: "CONTACT_US" })}</span>
                            </p>
                        </div>
                        <div style={{ padding: "0px 25px", maxWidth: "180px" }}>
                            <LanguageSwitcher refHead={refHead}></LanguageSwitcher>
                        </div>

                    </div>
            }

            {/* Content Section */}
            {
                parallaxView === false ?
                    //No Parallax
                    <>
                        <div className="section1Wrapper" ref={refHead}>
                            <div className="cloudBg1Container"></div>
                            {
                                phoneView === false ?
                                    <>
                                        <div className="cloudBg2Container"></div>
                                        <div className="cloudBg3Container"></div>
                                    </>
                                    :
                                    null
                            }

                            <div className="logoDiv">
                                <img alt="logoImage" className="logoImage" src={appLogo} />
                            </div>
                        </div>
                    </>
                    :
                    //Parallax
                    <>
                        <div className="parallaxContainer" ref={refHead}>
                            <ParallaxBanner style={{ aspectRatio: '1/ 0.5', position: "unset" }}>
                                <ParallaxBannerLayer className="cloud1Div" image={cloud1} speed={-50} />
                                <ParallaxBannerLayer className="cloudLeftDiv"
                                    image={cloudLeft}
                                    speed={-20}
                                    scale={cloud1Setting.scale}
                                    translateX={cloud1Setting.x}
                                    translateY={cloud1Setting.y}
                                    easing="easeInQuad"
                                />
                                <ParallaxBannerLayer className="cloudRightDiv"
                                    image={cloudRight}
                                    speed={-20}
                                    scale={cloud2Setting.scale}
                                    translateX={cloud2Setting.x}
                                    translateY={cloud2Setting.y}
                                    easing="easeInQuad"
                                />
                                {/* <ParallaxBannerLayer className="logoDiv"
                                image={appLogo}
                                speed={-20}
                                scale={[0.6, 0.3]}
                                translateY={['10px', '10px']}
                                easing="easeInQuad"
                            /> */}
                                <ParallaxBannerLayer className="logoDiv"
                                    image={appLogo}
                                    speed={-20}
                                    scale={logoSetting.scale}
                                    translateY={logoSetting.y}
                                    easing="easeInSine"
                                    opacity={[5, 0]}
                                />
                            </ParallaxBanner>
                        </div>
                    </>
            }
        </>
    )
}

export default Header;