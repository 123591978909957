import React, { useCallback, useEffect, useMemo } from 'react';
import './App.less';
import { IntlProvider } from 'react-intl';
import { useLayout } from './hooks/LayoutContext';
import messages_en from './assets/i18n/en.json';
import message_zh_CN from './assets/i18n/zh_CN.json';
import message_ms from './assets/i18n/ms.json';
import { Route, Routes, useLocation } from 'react-router-dom';
import Home from './pages/Home';
import { protectedRoutes, RouteInfo } from './routes-protected';
import { ParallaxProvider } from 'react-scroll-parallax';
import trackPathForAnalytics from './TrackPageForAnalytics';

function App() {
  const { layoutState } = useLayout();

  const messages: any = {
    'en': messages_en,
    'zh-CN': message_zh_CN,
    'ms': message_ms
  };

  const renderProtectedRoutes = useMemo(() => {
    return protectedRoutes.map((route: RouteInfo) => {
      if (route.children) {
        const childrenRoute = route.children.map((childRoute: RouteInfo) => {
          return (<Route key={childRoute.id} path={`${route.path}${childRoute.path}`} element={<childRoute.component />} />);
        });
        return [...childrenRoute];
      } else {
        return (<Route key={route.id} path={route.path} element={<route.component />} />);
      }
    });
  }, []);

  const { pathname, search } = useLocation();

  const analytics = useCallback(() => {
    trackPathForAnalytics({ path: pathname, search: search, title: pathname.split("/")[1] });
  }, [pathname, search]);

  useEffect(() => {
    analytics();
  }, [analytics]);

  return (
    <IntlProvider locale={layoutState.locale} messages={layoutState.locale ? messages[layoutState.locale] : ''} key={layoutState.locale}>
      <ParallaxProvider>
        <Routes>
          <Route path="/" element={<Home />}>
            {renderProtectedRoutes}
          </Route>
          <Route path="*" element={<Home />} />
        </Routes>
      </ParallaxProvider>
    </IntlProvider>
  );
}

export default App;
