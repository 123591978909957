import "./Home.less";
import Header from "./Header/Header";
import Products from "./Products/Products";
import Products2 from "./Products/Products2";
import Products3 from "./Products/Products3";
import { useEffect, useState, useRef } from "react";
import IngredientBg from "./Ingredients/IngredientBg";
// import Testimonial from "./Testimonial/Testimonial";
import VMission from "./CompanyProfile/VMission";
import Footer from "./Footer/Footer";
import ScrollToTop from "react-scroll-to-top";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp } from '@fortawesome/free-solid-svg-icons'
import "./Parallax/ParallaxEg.less"
const sizeLarge = window.matchMedia("(min-width: 1025px)");
const sizeMedium = window.matchMedia("(min-width: 769px) and (max-width: 1024px)");
const sizeSmall = window.matchMedia("(min-width: 577px) and (max-width: 768px)");
const sizeXSmall = window.matchMedia("(min-width: 426px) and (max-width: 576px)");
const sizeXXSmall = window.matchMedia("(min-width: 361px) and (max-width: 425px)");
const sizeXXXSmall = window.matchMedia("(min-width: 320px) and (max-width: 360px)");

const Home = () => {
    const { innerWidth: width } = window;
    const refHead = useRef(null);
    const refFooter = useRef(null);
    const refProduct = useRef(null);
    const refProduct2 = useRef(null);
    const refProduct3 = useRef(null);
    const refIngredientBg = useRef(null);
    const refVMission = useRef(null);
    const [phoneView, setPhoneView] = useState<boolean>(false);
    const [parallaxView, setParallaxView] = useState<boolean>(false);

    const [windowDimension, setWindowDimension] = useState<any>();

    useEffect(() => {

        if (sizeLarge.matches) {
            setWindowDimension('large')
        } else if (sizeMedium.matches) {
            setWindowDimension('medium')
        } else if (sizeSmall.matches) {
            setWindowDimension('small')
        } else if (sizeXSmall.matches) {
            setWindowDimension('xsmall')
        } else if (sizeXXSmall.matches) {
            setWindowDimension('xxsmall')
        } else if (sizeXXXSmall.matches) {
            setWindowDimension('xxxsmall')
        }

        if (width > 768) {
            setPhoneView(false);
        } else if (width <= 768) {
            setPhoneView(true);
        }

        if (width >= 320) {
            setParallaxView(true);
        } else if (width < 320) {
            setParallaxView(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        function handleResize() {
            if (sizeLarge.matches) {
                setWindowDimension('large')
            } else if (sizeMedium.matches) {
                setWindowDimension('medium')
            } else if (sizeSmall.matches) {
                setWindowDimension('small')
            } else if (sizeXSmall.matches) {
                setWindowDimension('xsmall')
            } else if (sizeXXSmall.matches) {
                setWindowDimension('xxsmall')
            } else if (sizeXXXSmall.matches) {
                setWindowDimension('xxxsmall')
            }
        }

        window.addEventListener('resize', handleResize, { once: false });
        // return () => (window.removeEventListener('resize', handleResize), window.removeEventListener('load', handleResize));
    }, []);

    return (
        <>
            <div className="backgroundGradient">
                <div className="contentWrapper">
                    <Header windowDimension={windowDimension ?? 'large'} parallaxView={parallaxView} refHead={refHead} refFooter={refFooter} refProduct={refProduct} refProduct2={refProduct2} refProduct3={refProduct3} refIngredientBg={refIngredientBg} refVMission={refVMission} phoneView={phoneView}></Header>
                    <Products windowDimension={windowDimension ?? 'large'} parallaxView={parallaxView} ref={refProduct} phoneView={phoneView}></Products>
                    <Products2 windowDimension={windowDimension ?? 'large'} parallaxView={parallaxView} phoneView={phoneView} ></Products2>
                    <Products3 windowDimension={windowDimension ?? 'large'} parallaxView={parallaxView} phoneView={phoneView}></Products3>
                    <IngredientBg windowDimension={windowDimension ?? 'large'} parallaxView={parallaxView} phoneView={phoneView} ref={refIngredientBg}></IngredientBg>
                    <VMission windowDimension={windowDimension ?? 'large'} parallaxView={parallaxView} phoneView={phoneView} ref={refVMission}></VMission>
                    <Footer windowDimension={windowDimension ?? 'large'} parallaxView={parallaxView} phoneView={phoneView} refProduct={refProduct} refIngredientBg={refIngredientBg} refVMission={refVMission} ref={refFooter}></Footer>

                    {
                        phoneView === false ?
                            <ScrollToTop style={{ marginBottom: "27px", zIndex: "99990" }} smooth top={500}
                                component={<FontAwesomeIcon icon={faChevronUp} fontSize={20} />} color="#000000" />
                            :
                            null

                    }
                </div>




            </div>


        </>
    )
}

export default Home;