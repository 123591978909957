import leafClip from '../../assets/images/nugen-asset/leaf.webp';
import leafClip2 from '../../assets/images/nugen-asset/leaf2.webp';
import prdImg1 from '../../assets/images/nugen-asset/productImg1.webp';
import "./Products.less";
import { useIntl } from "react-intl";
import { EffectNumber, EffectString, Parallax, ParallaxBanner, ParallaxBannerLayer } from "react-scroll-parallax";
import { useEffect, useRef, useState } from 'react';
import mestiClip from '../../assets/images/nugen-asset/mestiIcon.webp';
import nature100Clip from '../../assets/images/nugen-asset/100natureIcon.webp';
import halaliconClip from '../../assets/images/nugen-asset/halalicon.webp';
import gmpIconClip from '../../assets/images/nugen-asset/gmpIcon.webp';

interface Props {
    phoneView: any;
    windowDimension: any;
    parallaxView: any;
}

const leaf1Animate: { size: any, x: EffectString, y: EffectString, scale?: EffectNumber }[] = [
    { size: 'large', x: ['0%', '35%'], y: ['-50%', '0%'], scale: [0.12, 0.12] },
    { size: 'medium', x: ['0%', '35%'], y: ['-50%', '0%'], scale: [0.12, 0.12] },
    { size: 'small', x: ['0%', '35%'], y: ['-50%', '0%'], scale: [0.12, 0.12] },
    { size: 'xsmall', x: ['0%', '35%'], y: ['-50%', '0%'], scale: [0.15, 0.15] },
    { size: 'xxsmall', x: ['0%', '65%'], y: ['-50%', '0%'], scale: [0.2, 0.2] },
    { size: 'xxxsmall', x: ['0%', '65%'], y: ['-50%', '0%'], scale: [0.2, 0.2] },
]

const leaf2Animate: { size: any, x: EffectString, y: EffectString, scale?: EffectNumber }[] = [
    { size: 'large', x: ['0%', '-35%'], y: ['-50%', '-25%'], scale: [0.18, 0.18] },
    { size: 'medium', x: ['0%', '-35%'], y: ['-50%', '-25%'], scale: [0.18, 0.18] },
    { size: 'small', x: ['0%', '-35%'], y: ['-50%', '-15%'], scale: [0.18, 0.18] },
    { size: 'xsmall', x: ['0%', '-35%'], y: ['-50%', '-15%'], scale: [0.23, 0.23] },
    { size: 'xxsmall', x: ['0%', '-65%'], y: ['-50%', '-15%'], scale: [0.26, 0.26] },
    { size: 'xxxsmall', x: ['0%', '-65%'], y: ['-50%', '-15%'], scale: [0.26, 0.26] },
]

const prdImg1Animate: { size: any, x?: EffectString, y: EffectString, scale?: EffectNumber, opacity?: EffectNumber }[] = [
    // { size: 'large', y: ['30%', '-15%'], scale: [0.4, 0.4], opacity: [6, 0] },
    // { size: 'medium', y: ['30%', '-30%'], scale: [0.6, 0.6], opacity: [6, 0] },
    // { size: 'small', y: ['30%', '-25%'], scale: [0.6, 0.6], opacity: [6, 0] },
    // { size: 'xsmall', y: ['30%', '-15%'], scale: [0.8, 0.8], opacity: [6, 0] },
    // { size: 'xxsmall', y: ['50%', '-15%'], scale: [0.8, 0.8], opacity: [6, 0] },
    // { size: 'xxxsmall', y: ['50%', '-15%'], scale: [1, 1], opacity: [6, 0] },
    { size: 'large', y: ['35%', '-12%'], scale: [0.4, 0.4], opacity: [6, 0] },
    { size: 'medium', y: ['35%', '-12%'], scale: [0.6, 0.6], opacity: [6, 0] },
    { size: 'small', y: ['35%', '-13%'], scale: [0.6, 0.6], opacity: [8, 0] },
    { size: 'xsmall', y: ['35%', '-13%'], scale: [0.8, 0.8], opacity: [8, 0] },
    { size: 'xxsmall', y: ['55%', '-10%'], scale: [1.2, 1.2], opacity: [12, 0] },
    { size: 'xxxsmall', y: ['55%', '-10%'], scale: [1.2, 1.2], opacity: [12, 0] },
]

const Products2 = ({ phoneView, windowDimension, parallaxView }: Props) => {
    const intl = useIntl();
    const [leaf1Setting, setLeaf1Setting] = useState<any>({ size: 'large', x: ['0px', '0px'], y: ['0px', '0px'], scale: [0, 0] });
    const [leaf2Setting, setLeaf2Setting] = useState<any>({ size: 'large', x: ['0px', '0px'], y: ['0px', '0px'], scale: [0, 0] });
    const [prdImg1Setting, setPrdImg1Setting] = useState<any>({ size: 'large', x: ['0px', '0px'], y: ['0px', '0px'], scale: [0, 0], opacity: [0, 0] });
    const [targetElement, setTarget] = useState<HTMLDivElement | null>(null);
    const targetRef = useRef<null | HTMLDivElement>(null);

    useEffect(() => {
        const useSize = leaf1Animate.filter(x => x.size === windowDimension);
        const useSize2 = leaf2Animate.filter(x => x.size === windowDimension);
        const useSize3 = prdImg1Animate.filter(x => x.size === windowDimension);
        setLeaf1Setting(useSize[0]);
        setLeaf2Setting(useSize2[0]);
        setPrdImg1Setting(useSize3[0]);
    }, [windowDimension]);

    return (
        <>
            {
                parallaxView === false ?
                    <>
                        <div className="section3Wrapper">
                            <div className="productSection2">
                                <div className="contentSection">
                                    <div className="contentBox2">
                                        <div className="prdContent2">
                                            <div className="prdLeafClipContainer2">
                                                <img alt="leaf1" className="prdLeafClip" src={leafClip} />
                                            </div>
                                            <div className="prdLeafClipContainer3">
                                                <img alt="leaf2" className="prdLeafClip2" src={leafClip2} />
                                            </div>
                                            <div className="prdTextBox">
                                                <h2 className="titleSizing">{intl.formatMessage({ id: "PRODUCT_TITLE_1" })}</h2>
                                                <p className="fontSizing">{intl.formatMessage({ id: "PRODUCT_DESC_4" })}</p>
                                            </div>
                                            <img alt="prd1" className="productImg1Style" src={prdImg1} />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </>
                    :
                    <div className="parallaxContainerP3">
                        <ParallaxBanner style={{ aspectRatio: '1/0.5', position: "unset" }}>
                            <Parallax style={{ width: '100%', height: '50%', position: 'sticky', marginTop: '10vh', zIndex: 0 }}>
                                {/* control animation start end */}
                                <div ref={el => { targetRef.current = el; setTarget(el); }}></div>
                            </Parallax>
                            <ParallaxBannerLayer
                                speed={-35}
                                translateY={['500px', '100px']}
                                scale={[0.7, 1.2]}
                                opacity={[10, 0]}
                                easing="easeOutQuad"
                                children={
                                    (
                                        <div className="textBox2">
                                            <div className="prdContentBox2">                                               
                                                <div className="prdContentText2">
                                                    <h2 className="titleSizing">{intl.formatMessage({ id: "PRODUCT_TITLE_1" })}</h2>
                                                    <p className="fontSizing">{intl.formatMessage({ id: "PRODUCT_DESC_4" })}</p>
                                                </div>
                                                <div className="product-logo-official">
                                                    <img style={{marginLeft:'0px'}}alt="mesti" src={mestiClip} />
                                                    <img alt="nature100" src={nature100Clip} />
                                                    <img alt="halal" src={halaliconClip} />
                                                    <img alt="gmp" src={gmpIconClip} />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            ></ParallaxBannerLayer>
                            <ParallaxBannerLayer className="prdImg1Div"
                                image={prdImg1}
                                speed={-20}
                                translateY={prdImg1Setting.y}
                                scale={prdImg1Setting.scale}
                                opacity={prdImg1Setting.opacity}
                                easing="easeOutQuad"
                            />
                            {
                                targetElement !== null ?
                                    <>
                                        <ParallaxBannerLayer className="prdLeafClipA"
                                            targetElement={targetElement}
                                            image={leafClip}
                                            speed={-30}
                                            translateX={leaf1Setting.x}
                                            translateY={leaf1Setting.y}
                                            scale={leaf1Setting.scale}
                                            opacity={[0, 3]}
                                            easing="easeInOutQuad"
                                        />
                                        <ParallaxBannerLayer className="prdLeafClipB"
                                            targetElement={targetElement}
                                            image={leafClip2}
                                            speed={-30}
                                            translateX={leaf2Setting.x}
                                            translateY={leaf2Setting.y}
                                            scale={leaf2Setting.scale}
                                            opacity={[0, 3]}
                                            easing="easeInOutQuad"
                                        />
                                    </>
                                    :
                                    <></>
                            }
                        </ParallaxBanner>
                    </div>
            }
        </>
    )
}

export default Products2;